import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
} from 'azgaz-connect';

import { useHistory, Link } from 'react-router-dom';
import FavouritesPopover from '../FavouritesPopover';
import style from './style.module.scss';

type CarsBarFavouritesProps = {
  asLink?: boolean;
};

const CarsBarFavourites: FC<CarsBarFavouritesProps> = ({ asLink = true }) => {
  const history = useHistory();
  const [visiblePopover, setVisiblePopover] = useState(false);
  const favouritesTotal = useSelectorConnect(
    selectorsConnect.favourites.favouritesTotal
  );
  const favouritesCount = React.useRef(favouritesTotal);

  const Content = useCallback(
    () => (
      <>
        В избранном: <span className={style.count}>{favouritesTotal}</span>
      </>
    ),
    [favouritesTotal]
  );

  const goToFavourites = () => {
    history.push('/favourites');
  };

  useEffect(() => {
    if (favouritesCount.current < favouritesTotal) {
      setVisiblePopover(true);
    } else {
      setVisiblePopover(false);
    }

    favouritesCount.current = favouritesTotal;
  }, [favouritesTotal]);

  return (
    <div className={style.wrap}>
      {asLink ? (
        <FavouritesPopover
          visible={visiblePopover}
          content={
            <Link to="/favourites" className={style.link}>
              Перейти в Избранное
            </Link>
          }
        >
          <button
            className={style.btnPopover}
            type="button"
            onClick={goToFavourites}
          >
            <Content />
          </button>
        </FavouritesPopover>
      ) : (
        <div className={style.content}>
          <Content />
        </div>
      )}
    </div>
  );
};

export default CarsBarFavourites;
