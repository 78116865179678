import React, { FC } from 'react';
import style from './style.module.scss';

type MessageProps = {
  type: 'error' | 'success';
};

const Message: FC<MessageProps> = ({ type, children }) => {
  return <div className={style[type]}>{children}</div>;
};

export default Message;
