import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCarModels } from '@/apps/appCreator/store/carModels';
import { getCarModels } from '@/apps/appCreator/selectors';

export default () => {
  const dispatch = useDispatch();
  const carModels = useSelector(getCarModels);

  useEffect(() => {
    if (carModels.models.length === 0) {
      dispatch(fetchCarModels());
    }
  }, []);
};
