type Fields = { [key: string]: any };

function applyOnSubmitHandler(
  fields: Fields,
  handler: (fields: Fields) => Fields
): Fields {
  const resolvedFields =
    typeof handler === 'function' ? handler({ ...fields }) || fields : fields;

  return resolvedFields;
}

export default applyOnSubmitHandler;
