import { TFields } from './connectForm';

/**
 * Преобразует тип поля rc-form в key: value объект
 */
export default <T = { [K in keyof TFields]: TFields[K]['value'] }>(
  formState: TFields
) =>
  Object.entries(formState).reduce<T>((acc, [key, rcValue]) => {
    acc[key] = rcValue.value;
    return acc;
  }, {} as T);
