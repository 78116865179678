export default {
  personalData: `Настоящим, я даю свое согласие оператору сайта на обработку моих
    персональных данных, указанных мною в полях заявки (регистрации)
    на настоящем Сайте, путем совершения любых действий с
    использованием средств автоматизации в информационных системах или
    без использования таких средств, включая сбор, запись,
    систематизацию, накопление, хранение, уточнение (обновление,
    изменение), извлечение, использование, передачу (распространение,
    предоставление, доступ), обезличивание, блокирование, удаление,
    уничтожение, и передачу моих персональных данных третьим лицам,
    которые будут осуществлять обработку персональных данных по
    договору и поручению с оператором сайта. Обработка персональных
    данных осуществляется с целью: ведения и актуализации клиентской
    базы, продвижения на рынке товаров марки «ГАЗ» и связанных с ними
    работ и услуг, включая информирование о новостях, акциях и
    скидках, о новых продуктах марки «ГАЗ», проведение маркетинговых
    опросов, опросов в рамках программы «Оценка качества обслуживания»
    и других мероприятий. С Политикой в отношении обработки
    персональных данных ознакомлен.`,
  gettingInfo: `Настоящим, я выражаю свое согласие на получение мною рекламной и
    иной информации в виде смс-сообщений и электронных сообщений на
    указанный мною телефон и электронный адрес, а также устно,
    посредством звонка на указанный мною телефон с целью продвижения на
    рынке товаров марки «ГАЗ» и связанных с ними работ и услуг. Согласие
    действует бессрочно и может быть отозвано посредством направления
    мною письменного уведомления по почтовому адресу или email,
    указанным в разделе "Контакты".`,
};
