import { createSelector } from '@reduxjs/toolkit';
import { Car } from 'azgaz-connect';
import { calcDiscountPrice } from '@/utils';
import getCurrentDiscounts from './getCurrentDiscounts';
import getCarPriceInfo from './getCarPriceInfo';
import { State } from '../store';

export default createSelector(
  getCurrentDiscounts,
  (state: State, car: Car | undefined) => getCarPriceInfo(car),
  (currentDiscounts, carPriceInfo) =>
    calcDiscountPrice(carPriceInfo, currentDiscounts)
);
