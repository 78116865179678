import { useState } from 'react';

export default (collapses: boolean[]) => {
  const handlers = collapses.map(initState => {
    const [visibility, setVisibility] = useState(initState);

    const toggle = () => setVisibility(!visibility);
    const hide = () => setVisibility(false);

    return {
      visibility,
      setVisibility,
      toggle,
      hide,
    };
  });

  return handlers;
};
