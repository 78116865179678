import React, { Component } from 'react';
import cn from 'classnames';
import AntRadio, { Group, RadioProps } from 'antd/lib/radio';
import 'antd/lib/radio/style/index.css';
import style from './style.module.scss';

// eslint-disable-next-line react/prefer-stateless-function
class Radio extends Component<RadioProps> {
  render() {
    const { children, className, ...restProps } = this.props;

    return (
      <AntRadio
        className={cn(style.radio, className)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      >
        {children}
      </AntRadio>
    );
  }
}

export { Radio as default, Group };
