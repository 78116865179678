import { useCallback, useState, FormEvent } from 'react';

type useFormHandlersType = {
  form: any;
  submitAction: Function;
};

export default (props: useFormHandlersType) => {
  const [isTrySubmit, setIsTrySubmited] = useState(false);
  const { form, submitAction } = props;

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsTrySubmited(true);

      form.validateFields((errors: any, values: any) => {
        if (errors) return;
        const result = submitAction(values);

        if (result instanceof Promise) {
          result.then(() => setIsTrySubmited(false));
        }
      });
    },
    [submitAction]
  );

  const getError = (field: string) => {
    const errors: string[] | undefined = form.getFieldError(field);
    return isTrySubmit ? errors && errors[0] : undefined;
  };

  return {
    isTrySubmit,
    setIsTrySubmited,
    onSubmit,
    getError,
  };
};
