import { creditTypes, Discounts } from '@/apps/appCreator/store/discounts';
import { getCarPriceInfo } from '../apps/appCreator/selectors';

type Values = ReturnType<typeof getCarPriceInfo>; // Параметры выгод текущего авто
type Options = Discounts; // Выбранные пользователем выгоды

/**
 * Стоимость тачки со всеми выбранными пользователем скидками
 */
export default (
  {
    price,
    tradeIn: tradeInDiscount,
    credit: creditDiscount,
    leasing: leasingDiscount,
  }: Values,
  { tradeIn, credit, creditType }: Options
): number => {
  let discountPrice = price;

  if (tradeIn && tradeInDiscount) {
    discountPrice -= tradeInDiscount;
  }

  if (credit) {
    switch (creditType) {
      case creditTypes.creditDiscount:
        if (creditDiscount) {
          discountPrice -= creditDiscount;
        }
        break;
      case creditTypes.leasingDiscount:
        if (leasingDiscount) {
          discountPrice -= leasingDiscount;
        }
        break;
      default:
        throw new Error('Некорректный тип кредита');
    }
  }

  return discountPrice;
};
