import { Car } from 'azgaz-connect';
import { ValueOf } from '@/types';
import { creditTypes } from '@/apps/appCreator/store/discounts';

/**
 * Определяет дефолтный тип скидки для автомобиля
 */
export default function(
  car: Car | undefined,
  defaultType = creditTypes.creditDiscount
) {
  let type: ValueOf<typeof creditTypes> = defaultType;

  if (car && !car.price.credit_discount && car.price.leasing_discount) {
    type = creditTypes.leasingDiscount;
  }

  return type;
}
