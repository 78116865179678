import React from 'react';
import { Link } from 'react-router-dom';
import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
} from 'azgaz-connect';

import style from './style.module.scss';

const CarBar = () => {
  const favouritesTotal = useSelectorConnect(
    selectorsConnect.favourites.favouritesTotal
  );

  return (
    <div className={style.wrap}>
      <div className="container">
        <div className={style.barList}>
          <Link to="/favourites" className={style.link}>
            Избранное: <span className={style.value}>{favouritesTotal}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CarBar;
