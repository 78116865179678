import dynamicBaseName from '@/utils/dynamicBaseName';

interface Config {
  [key: string]: any;
}

const config: Config = {
  // По умолчанию basename определяется динамически.
  // Можно переопределить ч/з .env файл или при инициализации приложения.
  basename: dynamicBaseName(),
  carsTitle: 'Автомобили в наличии',
};

function get(key: string[]): any[];
function get(key: string): any;
function get(key: string | string[]): any {
  let param;

  if (typeof key === 'string') {
    param = config[key];
  } else if (Array.isArray(key)) {
    param = key.map(i => config[i]);
  }

  return param;
}

function set(mod: object): void;
function set(key: string, value: any): void;
function set(key: object | string, value?: any) {
  if (typeof key === 'object') {
    Object.assign(config, key);
  } else {
    config[key] = value;
  }
}

function all(): Config {
  return config;
}

export default {
  get,
  set,
  all,
};

export { Config };
