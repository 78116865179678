import { createSelector } from '@reduxjs/toolkit';
import { Car } from 'azgaz-connect';

/**
 * Платежная информация для переданного автомобиля
 */
export default createSelector(
  (car: Car | undefined) => car,
  car => {
    const info: Record<string, any> = {
      nds: 0,
      tradeIn: 0,
      credit: 0,
      leasing: 0,
      price: 0,
      disclaimers: {
        tradeInDisclaimer: '',
        creditDisclaimer: '',
        leasingDisclaimer: '',
        order: [''],
      },
    };

    if (!car?.price) return info;

    info.price = car.price.value?.[0][0] || 0;
    info.tradeIn = car.price.trade_in_discount || 0;
    info.credit = car.price.credit_discount || 0;
    info.leasing = car.price.leasing_discount || 0;
    info.nds = car.price.nds || 0;

    const { disclaimers } = car.price;

    info.disclaimers.tradeInDisclaimer =
      disclaimers?.trade_in_discount_disclaimer;
    info.disclaimers.creditDisclaimer = disclaimers?.credit_discount_disclaimer;
    info.disclaimers.leasingDisclaimer =
      disclaimers?.leasing_discount_disclaimer;

    // записываем порядок дисклеймеров
    Object.keys(info).forEach(k => {
      if (['tradeIn', 'credit', 'leasing'].includes(k) && info[k]) {
        info.disclaimers.order.push(k);
      }
    });

    return info;
  }
);
