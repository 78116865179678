/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValueOf } from '@/types';

export const creditTypes = {
  creditDiscount: 'creditDiscount',
  leasingDiscount: 'leasingDiscount',
} as const;

export const fields = {
  tradeIn: 'tradeIn',
  credit: 'credit',
  creditType: 'creditType',
} as const;

export type CreditTypes = ValueOf<typeof creditTypes>;

export type Discounts = {
  [fields.tradeIn]: boolean;
  [fields.credit]: boolean;
  [fields.creditType]: CreditTypes;
};

type SliceStateDiscount = {
  discounts: Discounts;
};

const initialState: SliceStateDiscount = {
  discounts: {
    [fields.tradeIn]: false,
    [fields.credit]: false,
    [fields.creditType]: 'creditDiscount',
  },
};

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    setDiscounts(
      state,
      action: PayloadAction<{ [K in keyof Partial<Discounts>]: Discounts[K] }>
    ) {
      state.discounts = {
        ...state.discounts,
        ...action.payload,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export default discountsSlice;

export const discountsActions = discountsSlice.actions;
